<script>
    import debounce from 'lodash.debounce';
    import collect from 'collect.js';
    import CalendarEvent from './calendar/CalendarEvent.vue';
    import CalendarFilter from './calendar/CalendarFilter.vue';
    import { filterByQuery } from '../helpers/filterByQuery.js';

    export default {
        components: {
            CalendarEvent,
            CalendarFilter,
        },
        props: {
            events: {
                type: Array,
                required: true,
            },
            isSoundguide: {
                type: Boolean,
                default: false,
            },
            soundguideMapUrl: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                stickyTop: 42,
                filterQuery: {},
                availableFilterCriteria: {},
                locale: 'en',
                showFilter: false,
            };
        },
        computed: {
            filteredEvents() {
                return collect(this.events).filter((event) =>
                    filterByQuery(this.filterQuery, event),
                );
            },
            groupedEvents() {
                return this.filteredEvents.groupBy('month').all();
            },
            eventsCount() {
                return this.filteredEvents.count();
            },
        },
        created() {
            this.getAvailableFilterCriteria();
            this.parseQueryString();
        },
        mounted() {
            // Get height of navigation to position month header
            window.addEventListener('resize', this.getStickyHeight);
            this.getStickyHeight();
            // Get locale to translate strings
            this.locale = document.documentElement.getAttribute('lang');
            // Show filter if a filter is applied
            if (collect(this.filterQuery).flatten().count() > 0) {
                this.showFilter = true;
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getStickyHeight);
        },
        methods: {
            getStickyHeight: debounce(function () {
                this.stickyTop = document
                    .querySelector('.siteHeader')
                    .getBoundingClientRect().height;
            }, 250),
            parseQueryString() {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                this.filterQuery = {};

                [
                    'month',
                    'online',
                    'eventType',
                    'venue',
                    'participant',
                    'genre',
                    'accessibility',
                ].forEach((name) => {
                    this.filterQuery[name] = searchParams
                        .getAll(name)
                        .filter((p) =>
                            this.availableFilterCriteria[name].includes(p),
                        );
                });
            },
            getAvailableFilterCriteria() {
                const events = collect(this.events);
                this.availableFilterCriteria = {};

                if (!this.isSoundguide) {
                    this.availableFilterCriteria['online'] = ['Online', 'Live'];
                }

                let visibleFilters = this.isSoundguide
                    ? // Soundguide Calendar filters
                      ['eventType', 'month', 'genre', 'venue', 'accessibility']
                    : // Regular Calendar filters
                      ['eventType', 'venue', 'month', 'participant'];

                visibleFilters.forEach((name) => {
                    this.availableFilterCriteria[name] = events
                        .pluck(name)
                        .flatten()
                        .unique()
                        .filter()
                        .all();
                });
            },
        },
    };
</script>

<template>
    <div
        v-cloak
        class="calendar"
        :class="{
            'calendar--openFilter': showFilter,
            'calendar--inverted': isSoundguide,
        }"
    >
        <slot name="intro" />
        <calendar-filter
            :locale="locale"
            :available-filter-criteria="availableFilterCriteria"
            :is-open="showFilter"
            :inverted="isSoundguide"
            @close="showFilter = false"
            @updated-query="parseQueryString"
        />
        <div class="calendar__past">
            <!-- v-show="eventsCount > 0"-->
            <!-- :style="{ top: stickyTop + 'px' }" -->
            <slot name="past" />
        </div>
        <div
            v-for="(events, month) in groupedEvents"
            :key="'cal_month' + month"
            class="calendar__month"
        >
            <div
                class="calendar__monthHeader"
                :style="{ top: stickyTop + 'px' }"
            >
                {{ month }}
            </div>
            <transition-group name="cal-event-transition" tag="div">
                <calendar-event
                    v-for="(event, index) in events.all()"
                    :key="'event' + event.slug + index"
                    :data-index="index"
                    :style="{ '--transition-delay': index * 30 + 'ms' }"
                    :event="event"
                    :is-soundguide="isSoundguide"
                    :soundguide-map-url="soundguideMapUrl"
                    :locale="locale"
                />
            </transition-group>
        </div>
        <div v-show="eventsCount === 0" class="calendar__noEvents">
            <slot />
        </div>
        <div class="switcher" :class="{ 'switcher--inverted': isSoundguide }">
            <button
                class="switcher__button switcher__button--filter"
                title="toggle Filter"
                @click="showFilter = !showFilter"
            >
                <span class="switcher__hidden">Toggle filter</span>
            </button>
        </div>
    </div>
</template>
