import Vue from 'vue';
import vClickOutside from 'v-click-outside';

// Change vue delimiters, to use vue in twig and blade templates.
// This doesn't change the delimiters in .vue files
Vue.mixin({ delimiters: ['${', '}'] });

// ------------------------------------------------------------------------
// Vue Plugins
// ------------------------------------------------------------------------
Vue.use(vClickOutside);

