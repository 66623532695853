import canAutoPlay from 'can-autoplay';

const videoUrls = {
    landscape: {
        'mp4': 'https://video.bunnycdn.com/play/109909/1295cb16-ec6a-4036-90db-aa22a3bb06da',
        'playlist': 'https://vz-6080a0fa-38a.b-cdn.net/1295cb16-ec6a-4036-90db-aa22a3bb06da/playlist.m3u8',
    },
    portrait: {
        'mp4': 'https://video.bunnycdn.com/play/109909/c63953c1-8246-4bc1-baa9-e75bb1917d82',
        'playlist': 'https://vz-6080a0fa-38a.b-cdn.net/c63953c1-8246-4bc1-baa9-e75bb1917d82/playlist.m3u8',
    },
};

export default () => ({
    hls: null,

    async init() {
        await this.initVideo();
        if (this.$store.muted === false) {
            // Try enabling audio
            canAutoPlay
                .video({ timeout: 100, muted: false })
                .then(({ result }) => {
                    if (result === true) {
                        if(this.$refs.video) {
                            this.$refs.video.muted = this.$store.muted;
                        }
                    } else {
                        this.$store.muted = true;
                    }
                });
        }
    },

    async initVideo() {

        const isLandscape = window.matchMedia("(orientation: landscape)").matches;
        const videoUrl = isLandscape ? videoUrls.landscape : videoUrls.portrait;

        const video = this.$refs.video;
        let videoSrc = videoUrl.playlist;
        const { default: Hls } = await import('hls.js');

        if (video.canPlayType('application/vnd.apple.mpegurl')) {
            let source = document.createElement('source');
            source.setAttribute('src', videoSrc);
            video.appendChild(source);
        } else if (Hls.isSupported()) {
            this.hls = new Hls({
                startLevel: 3,
            });
            this.hls.loadSource(videoSrc);
            this.hls.attachMedia(video);
        } else {
            let source = document.createElement('source');
            source.setAttribute('src', videoUrl.mp4);
            video.appendChild(source);
        }
    },

    toggleMute() {
        this.$store.muted = !this.$store.muted;
        if(this.$refs.video) {
            this.$refs.video.muted = this.$store.muted;
        }
    },

    destroy() {
        if (this.hls) {
            this.hls.destroy();
        }
    },
});
