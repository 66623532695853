const mdMediaQuery = window.matchMedia("(min-width: 768px)");

export default (activeIds) => ({
    open: false,
    md: mdMediaQuery.matches,
    activeIds,

    init() {
        mdMediaQuery.addEventListener(
            "change",
            (e) => (this.md = e.matches)
        );

        this.$watch('md', () => {
            this.open = false;
        })
    },

    isOpen(id) {
        return this.activeIds.includes(id);
    },

    toggle(ids) {
        // get the last item of array
        const lastId = ids[ids.length - 1];
        if(this.isOpen(lastId)) {
            ids.pop();
        }

        this.activeIds = ids;
    },
});
