import collect from "collect.js";

export const filterByQuery = (filterQuery, event) => {
    // Filter Live / Online
    if (filterQuery.online && filterQuery.online.length > 0) {
        if (
            (filterQuery.online
                .map((f) => f.toLowerCase())
                .includes('online') &&
                !event.isOnline) ||
            (filterQuery.online.map((f) => f.toLowerCase()).includes('live') &&
                event.isOnline)
        ) {
            return false;
        }
    }

    // console.log(filterQuery);

    // loop through all the filterQuery keys
    // for (const key of ['participant', 'genre', 'eventType', 'accessibility']) {
    //     console.log(key, filterQuery[key]);
    // }

    // Filter criteria that have a single value
    // for (const singleValue of ['month', 'venue']) {
    //     if (filterQuery[singleValue] && filterQuery[singleValue].length > 0) {
    //         // Ensure that it is a collection
    //         try {
    //             if (
    //                 !collect(filterQuery[singleValue])
    //                     .map((f) => f.toLowerCase())
    //                     .includes(event[singleValue].toLowerCase())
    //             ) {
    //                 return false;
    //             }
    //         } catch (e) {
    //             // todo: flare!
    //             console.error(e);
    //             return false;
    //         }
    //     }
    // }

    // Filter criteria with multiple values
    for (const multipleValue of ['month', 'venue', 'participant', 'genre', 'eventType', 'accessibility']) {
        if (filterQuery[multipleValue] && filterQuery[multipleValue].length > 0) {
            // console.log(multipleValue, filterQuery[multipleValue]);
            // Ensure that it is a collection
            try {
                if (
                    !collect(filterQuery[multipleValue])
                        .map((f) => f.toLowerCase())
                        .some((r) =>
                            // console.log('b', event, multipleValue, event[multipleValue]),
                            collect(event[multipleValue]).map((f) => f.toLowerCase()).toArray().includes(r),
                        )
                ) {
                    return false;
                }
            } catch (e) {
                // todo: flare!
                console.error(e);
                return false;
            }
        }
    }

    return true;
};
