import Swup from 'swup';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
// import SwupScriptsPlugin from '@swup/scripts-plugin';
// import SwupA11yPlugin from '@swup/a11y-plugin';

export const swup = new Swup({
    containers: ['#swup'],
    plugins: [
        // new SwupA11yPlugin(),
        // new SwupScriptsPlugin({
        //     head: false,
        //     body: true,
        // }),
        new SwupPreloadPlugin(),
        new SwupBodyClassPlugin({
            prefix: 'page-',
        }),
        new SwupProgressPlugin({
            delay: 500,
        }),
    ],
});
