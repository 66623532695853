import { swup } from '../swup.js';

export default () => ({
    open: true,

    init() {
        swup.hooks.on('content:replace', (visit) => {
            if (['', '/', '/en', '/en/'].includes(visit.to.url)) {
                this.open = true;
            }
        });
    },
});
