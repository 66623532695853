<script>
    export default {
        props: {
            event: {
                type: Object,
                required: true,
            },
            isSoundguide: {
                type: Boolean,
                default: false,
            },
            soundguideMapUrl: {
                type: String,
                default: '',
            },
            locale: {
                type: String,
                default: 'en',
            },
        },
        data() {
            return {
                labels: {
                    map: {
                        en: 'soundguide Map',
                        de: 'soundguide Karte',
                    },
                }
            };
        },
    };
</script>

<template>
    <div class="calendar__event">
        <div class="calendar__eventInner">
            <div class="calendar__eventDate">
                <time :datetime="event.startDate">
                    {{ event.day }}
                </time>
            </div>
            <div class="calendar__eventData">
                <h3 class="calendar__eventTitle">
                    <a :href="event.url">
                        {{ event.title }}
                    </a>
                </h3>
                <div class="calendar__eventDetails">
                    <div>
                        <p
                            v-if="event.timeOverwrite"
                            v-html="event.timeOverwrite"
                        />
                        <p v-else-if="!event.isAllDay && event.startTime">
                            {{ event.startTime }}h
                        </p>
                        <p v-if="event.genre && event.genre.length">
                            {{ event.genre.join(', ') }}
                        </p>
                        <!--                        <p-->
                        <!--                            v-if="-->
                        <!--                                event.accessibility &&-->
                        <!--                                event.accessibility.length-->
                        <!--                            "-->
                        <!--                        >-->
                        <!--                            {{ event.accessibility.join(', ') }}-->
                        <!--                        </p>-->
                        <p v-if="event.venue">
                            {{ event.venue }}
                        </p>
                        <p v-if="event.ticketLink" class="calendar__ticketLink">
                            <a
                                :href="event.ticketLink"
                                :target="event.ticketLinkTarget"
                                rel="noopener"
                                >Tickets</a
                            >
                        </p>
                        <p v-if="isSoundguide && soundguideMapUrl" class="calendar__ticketLink">
                            <a
                                :href="soundguideMapUrl"
                            >{{ labels.map[locale] }}</a
                            >
                        </p>
                    </div>
                    <div class="calendar__eventDetailsRight">
                        <a :href="event.url">details +</a>
                        <div
                            v-if="
                                event.isWheelchairAccessible ||
                                (event.accessibility &&
                                    event.accessibility.length)
                            "
                        >
                            <img
                                class="calendar__wheelchair"
                                :src="
                                    isSoundguide
                                        ? '/assets/icons/wheelchair-white.svg'
                                        : '/assets/icons/wheelchair.svg'
                                "
                                alt="Event is wheelchair-accessible"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
