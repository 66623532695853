import Vue from 'vue';
import './bootstrap.js';
import './vue.js';
import { swup } from './swup.js';
import { mountVue, unMountVue } from "./helpers/vueContainer";

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import ui from '@alpinejs/ui';

import siteHeader from './components/siteHeader.js';
import popup from './components/popup.js';
import homeVideo from './components/homeVideo.js';

// Import Vue components
import CalendarComponent from "./components/CalendarComponent.vue";
import SoundguideMap from "./components/SoundguideMap.vue";

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(ui);

Vue.component('calendar-component', CalendarComponent);
Vue.component('soundguide-map', SoundguideMap);

// Mount Vue components
let mountedVueComponents = mountVue('.swup-vue');

swup.hooks.on('content:replace', () => {
    unMountVue(mountedVueComponents);
}, { before: true });

swup.hooks.on('content:replace', (visit) => {
    mountedVueComponents = mountVue('.swup-vue');
});

document.addEventListener('alpine:init', () => {
    Alpine.data('siteHeader', siteHeader);
    Alpine.data('popup', popup);
    Alpine.data('homeVideo', homeVideo);
    Alpine.store('muted', 'true');
});

Alpine.start();
