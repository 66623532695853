<script>
    import L, { latLng } from 'leaflet';
    import 'leaflet/dist/leaflet.css';
    import { LIcon, LMap, LMarker, LPopup } from 'vue2-leaflet';
    import 'leaflet.tilelayer.colorfilter';
    import collect from 'collect.js';
    import {
        format,
        toDate,
        addMonths,
        subMonths,
        parse,
        isValid,
    } from 'date-fns';
    import SoundguideModal from './SoundguideModal.vue';
    import CalendarFilter from './calendar/CalendarFilter.vue';
    import { filterByQuery } from '../helpers/filterByQuery.js';


    const mapBoxToken =
        'pk.eyJ1Ijoibm9vcmRlcm1lZXIiLCJhIjoiY2w3eWhoNTZhMDVucjN2bHd4YXE2aDM5bSJ9.Mt-w42CiTbVYQHhYpRfAug';

    export default {
        components: {
            LMap,
            LMarker,
            LIcon,
            LPopup,
            SoundguideModal,
            CalendarFilter,
        },
        props: {
            availableFilterCriteria: {
                type: Object,
                required: true,
            },
            longplayEvents: {
                type: Array,
                required: true,
            },
            contributeUrl: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                zoom: 12,
                max: 18,
                min: 11,
                maxBounds: L.latLngBounds([
                    [47.692582, 8.106871],
                    [47.193346, 9.203543],
                ]),
                center: latLng(47.37969554172786, 8.53170908699115),
                // url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                url:
                    'https://api.mapbox.com/styles/v1/noordermeer/cl7yhipjr000o15qa0gotc42q/tiles/256/{z}/{x}/{y}@2x?access_token=' +
                    mapBoxToken,

                showModal: false,
                modalEntries: [],

                eventData: [],
                isLoadingEventData: true,
                showLoadingIndicator: true,
                monthLabel: '',

                // Filter
                currentDate: new Date(),
                filterQuery: {},
                locale: 'en',
                showFilter: false,
            };
        },
        computed: {
            currentMonthName() {
                const month = this.currentDate.toLocaleString(this.locale, {
                    month: 'long',
                });
                if (
                    format(this.currentDate, 'yyyy') !==
                    format(new Date(), 'yyyy')
                ) {
                    return month + ' ' + format(this.currentDate, 'yyyy');
                }
                return month;
            },
            currentYearMonthKey() {
                return format(this.currentDate, 'yyyyMM');
            },

            filteredEvents() {
                return this.isLoadingEventData
                    ? collect([])
                    : collect([...this.eventData, ...this.longplayEvents])
                          .filter((event) => event.coordinates)
                          .filter((event) => event.coordinates !== 'NaN,NaN')
                          .filter((event) => event.coordinates !== ',')
                          .filter(
                              (event) =>
                                  ['default'].includes(event.calendar) ||
                                  filterByQuery(this.filterQuery, event),
                          );
            },

            groupedMarkers() {
                if (this.filteredEvents.isEmpty()) {
                    return [];
                }

                return (
                    this.filteredEvents
                        // Group events by coordinates
                        .mapToGroups((event) => [event.coordinates, event])
                        // further group by calendar
                        .map((event, index) => {
                            return collect(event)
                                .mapToGroups((event) => [event.calendar, event])
                                .all();
                        })
                        .all()
                );
            },
            dynamicSize() {
                return [this.zoomSize, this.zoomSize];
            },
            dynamicAnchor() {
                return [this.zoomSize / 2, this.zoomSize / 2];
            },
            zoomSize() {
                return 10 + Math.max(this.zoom * 1.5 - 10, 0);
            },
        },
        created() {
            // Get locale to translate strings
            this.locale = document.documentElement.getAttribute('lang');

            this.parseQueryString();
            this.fetchEventData();
        },
        mounted() {
            const map = this.$refs.map.mapObject;
            L.tileLayer
                .colorFilter(this.url, {
                    filter: ['contrast:90%', 'hue:20deg', 'brightness:1.9'],
                })
                .addTo(map);

            // Show filter if a filter is applied
            if (collect(this.filterQuery).flatten().count() > 0) {
                this.showFilter = true;
            }
        },
        watch: {
            currentYearMonthKey() {
                this.fetchEventData();

                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                searchParams.set('month', this.currentYearMonthKey);
                this.updateQuery(searchParams);
            },
        },
        methods: {
            fetchEventData() {
                // console.log('Fetching events for ' + this.currentYearMonthKey);
                this.isLoadingEventData = true;
                let loadingDelay = setTimeout(
                    () => (this.showLoadingIndicator = true),
                    200,
                );
                this.eventData = [];
                fetch(
                    `/api/soundguide/events/${this.locale}/${this.currentYearMonthKey}`,
                )
                    .then((response) => response.json())
                    .then((data) => {
                        clearTimeout(loadingDelay);
                        this.eventData = data;
                        this.isLoadingEventData = false;
                        this.showLoadingIndicator = false;
                        this.monthLabel = this.currentMonthName;
                    });
            },

            navigateToPreviousMonth() {
                this.currentDate = toDate(subMonths(this.currentDate, 1));
            },
            navigateToNextMonth() {
                this.currentDate = toDate(addMonths(this.currentDate, 1));
            },

            zoomIn() {
                this.zoom += 0.5;
            },
            zoomOut() {
                this.zoom -= 0.5;
            },
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            centerUpdated(center) {
                this.center = center;
            },

            parseQueryString() {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                this.filterQuery = {};

                ['genre', 'eventType', 'accessibility'].forEach((name) => {
                    this.filterQuery[name] = searchParams
                        .getAll(name)
                        .filter((p) =>
                            this.availableFilterCriteria[name].includes(p),
                        );
                });

                // See if month is present
                if (searchParams.getAll('month').length > 0) {
                    const month = searchParams.getAll('month')[0];
                    const date = parse(month, 'yyyyMM', new Date());
                    this.currentDate = isValid(date) ? date : new Date();
                }

                // update query string
                searchParams.set('month', this.currentYearMonthKey);
                this.updateQuery(searchParams);
            },

            openModal(entries) {
                this.modalEntries = entries;
                this.showModal = true;
            },

            updateQuery(searchParams) {
                window.history.pushState(
                    '',
                    null,
                    `${window.location.pathname}${
                        searchParams ? '?' + searchParams : ''
                    }`,
                );
            },
        },
    };
</script>

<template>
    <div class="worldmap worldmap--soundguide">
        <calendar-filter
            :locale="locale"
            :available-filter-criteria="availableFilterCriteria"
            :is-open="showFilter"
            :inverted="true"
            :show-legend="true"
            @close="showFilter = false"
            @updated-query="parseQueryString"
        />
        <div class="switcher switcher--inverted">
            <button
                class="switcher__button switcher__button--filter"
                title="toggle Filter"
                @click="showFilter = !showFilter"
            >
                <span class="switcher__hidden">Toggle filter</span>
            </button>
        </div>

        <SoundguideModal
            :show="showModal"
            :entries="modalEntries"
            :locale="locale"
            @close="showModal = false"
        ></SoundguideModal>

        <l-map
            ref="map"
            :zoom="zoom"
            :max-zoom="max"
            :min-zoom="min"
            :center="center"
            :options="{
                zoomControl: false,
                worldCopyJump: true,
                maxBounds: maxBounds,
                zoomSnap: 0.5,
            }"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
        >
            <template v-for="(calendars, coordinates) in groupedMarkers">
                <l-marker
                    v-for="(entries, calendar, index) in calendars"
                    :lat-lng="coordinates.split(',').map(parseFloat)"
                    :key="
                        'marker' + currentYearMonthKey + coordinates + calendar
                    "
                    @click="openModal(entries)"
                >
                    <l-icon
                        :icon-anchor="dynamicAnchor"
                        :icon-size="dynamicSize"
                    >
                        <div
                            class="soundguide__marker"
                            :class="[
                                'soundguide__marker--' + calendar,
                                'soundguide__marker--' +
                                    (index + 1) +
                                    '/' +
                                    Object.keys(calendars).length,
                            ]"
                        ></div>
                    </l-icon>
                </l-marker>
            </template>
        </l-map>

        <div class="soundguideMapButtons">
            <div class="soundguideMonthSwitcher space-x-2 grow-0 shrink-0">
                <div
                    class="soundguideMonthSwitcher__arrow"
                    @click="navigateToPreviousMonth"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 31">
                        <g>
                            <path
                                fill="currentColor"
                                d="M1,15.5,15,1V13H49v5H15V30Z"
                            />
                        </g>
                    </svg>
                </div>
                <div v-if="!showLoadingIndicator">
                    {{ monthLabel }}
                </div>
                <div v-else>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            fill="currentColor"
                            class="spinner_nOfF"
                            cx="4"
                            cy="12"
                            r="3"
                        />
                        <circle
                            fill="currentColor"
                            class="spinner_nOfF spinner_fVhf"
                            cx="4"
                            cy="12"
                            r="3"
                        />
                        <circle
                            fill="currentColor"
                            class="spinner_nOfF spinner_piVe"
                            cx="4"
                            cy="12"
                            r="3"
                        />
                        <circle
                            fill="currentColor"
                            class="spinner_nOfF spinner_MSNs"
                            cx="4"
                            cy="12"
                            r="3"
                        />
                    </svg>
                </div>
                <div
                    class="soundguideMonthSwitcher__arrow soundguideMonthSwitcher__arrow--right"
                    @click="navigateToNextMonth"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 31">
                        <g>
                            <path
                                fill="currentColor"
                                d="M1,15.5,15,1V13H49v5H15V30Z"
                            />
                        </g>
                    </svg>
                </div>
            </div>

            <a
                v-if="contributeUrl"
                :href="contributeUrl"
                data-no-swup
                class="grow-0 mt-2 mx-auto inline-block uppercase rounded-full pr-3 pl-2 py-1 text-small bg-black text-white"
                >+ {{ locale === 'de' ? 'Neuer Eintrag' : 'New Entry' }}</a
            >
        </div>

        <div class="switcher switcher--map switcher--inverted">
            <a
                href="#"
                title="Zoom in"
                class="switcher__button switcher__button--zoom-plus"
                @click="zoomIn()"
            >
                <span class="switcher__hidden">Zoom in</span>
            </a>
            <a
                href="#"
                title="Zoom out"
                class="switcher__button switcher__button--zoom-minus"
                @click="zoomOut()"
            >
                <span class="switcher__hidden">Zoom Out</span>
            </a>
        </div>
    </div>
</template>
