<script>
    export default {
        props: {
            entries: {
                type: Array,
                required: true,
            },
            show: {
                type: Boolean,
                required: true,
            },
            locale: {
                type: String,
                default: 'en',
            },
        },
        data() {
            return {
                labels: {
                    time: {
                        en: 'Time',
                        de: 'Zeit',
                    },
                    venue: {
                        en: 'Venue',
                        de: 'Venue',
                    },
                    genre: {
                        en: 'Genre',
                        de: 'Genre',
                    },
                    program: {
                        en: 'Program',
                        de: 'Programm',
                    },
                    description: {
                        en: 'Description',
                        de: 'Beschreibung',
                    },
                    links: {
                        en: 'Links',
                        de: 'Links',
                    },
                    eventType: {
                        en: 'Event type',
                        de: 'Veranstaltungsart',
                    },
                    accessibility: {
                        en: 'Accessibility',
                        de: 'Barrierefreiheit',
                    },
                },
            };
        },
        computed: {
            entry() {
                return this.entries[0] ?? null;
            },
            isLongplayModal() {
                return this.entry?.calendar === 'longplay';
            },
            header() {
                return this.isLongplayModal
                    ? this.entry?.title
                    : this.entry?.venue;
            },
            calendarName() {
                return this.entry?.calendar;
            },
        },
        mounted() {},
        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>

<template>
    <transition name="soundguidemodal">
        <div
            v-if="show"
            class="soundguidemodal-mask fixed inset-0 overflow-y-auto"
        >
            <div v-if="show" class="soundguidemodal-bg fixed inset-0"></div>

            <div
                class="soundguidemodal-panel relative flex min-h-screen items-center justify-center"
            >
                <div
                    class="soundguidemodal-container relative overflow-y-auto mt-28 mb-20"
                    :class="['soundguidemodal-container--' + calendarName]"
                    v-click-outside="close"
                >
                    <div class="flex">
                        <h3 class="text-cal pb-[0.5em]">
                            {{ header }}
                        </h3>
                        <div
                            class="w-[40px] h-[40px] shrink-0 ml-4 cursor-pointer"
                            @click.prevent="close"
                        >
                            <button
                                class="welcomepopup__close"
                                style="right: 40px"
                            >
                                <span class="sr-only">Close</span>
                            </button>
                        </div>
                    </div>

                    <div class="text-base">
                        <!-- soundguide entries -->
                        <div
                            v-if="!isLongplayModal"
                            v-for="entry in entries"
                            :key="'modal' + entry.slug"
                            class="grid grid-cols-2 space-x-2 border-t last:border-b py-4"
                        >
                            <div class="flex flex-col justify-between">
                                <h3 class="break-words">{{ entry.title }}</h3>
                                <div>
                                    <a
                                        :href="entry.url"
                                        class="mt-2 inline-block uppercase rounded-full pr-3 pl-2 py-1 text-small"
                                        :class="[
                                            calendarName === 'soundguideEvents'
                                                ? 'bg-white text-black'
                                                : 'bg-black text-white',
                                        ]"
                                        >+ Details</a
                                    >
                                </div>
                            </div>
                            <ul>
                                <li>
                                    {{ entry.dateRange }}
                                </li>
                                <li>
                                    {{ entry.timeRange }}
                                </li>
                                <li v-for="genre in entry.genre">
                                    {{ genre }}
                                </li>
                                <li v-for="eventType in entry.eventType">
                                    {{ eventType }}
                                </li>
                                <li
                                    v-for="accessibility in entry.accessibility"
                                >
                                    {{ accessibility }}
                                </li>
                            </ul>
                        </div>

                        <!-- longplay entries -->
                        <div v-else>
                            <img
                                v-if="entry.image"
                                class="lazyload pb-2"
                                :data-srcset="entry.image"
                                data-sizes="auto"
                            />
                            <div class="detailViewPage__content">
                                <table class="detailViewPage__table">
                                    <!--                                    <tr-->
                                    <!--                                        v-if="!isLongplayModal"-->
                                    <!--                                        class="detailViewPage__groupedColumns"-->
                                    <!--                                    >-->
                                    <!--                                        <th>{{ labels['time'][locale] }}</th>-->
                                    <!--                                        <td class="detailViewPage__tableText">-->
                                    <!--                                            {{ entry.timeOverwrite }}-->
                                    <!--                                        </td>-->
                                    <!--                                    </tr>-->
                                    <tr
                                        :class="{
                                            detailViewPage__groupedColumns:
                                                entry.genre ||
                                                entry.eventType.length ||
                                                entry.accessibility.length,
                                        }"
                                    >
                                        <th>{{ labels['venue'][locale] }}</th>
                                        <td class="detailViewPage__tableText">
                                            {{ entry.venue }}
                                            <div v-if="entry.venueAddress">
                                                {{ entry.venueAddress }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="entry.genre"
                                        :class="{
                                            detailViewPage__groupedColumns:
                                                entry.eventType.length ||
                                                entry.accessibility.length,
                                        }"
                                    >
                                        <th>{{ labels['genre'][locale] }}</th>
                                        <td class="detailViewPage__tableText">
                                            {{ entry.genre.join(', ') }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="entry.eventType.length"
                                        :class="{
                                            detailViewPage__groupedColumns:
                                                entry.accessibility.length,
                                        }"
                                    >
                                        <th>
                                            {{ labels['eventType'][locale] }}
                                        </th>
                                        <td class="detailViewPage__tableText">
                                            <ul>
                                                <li
                                                    v-for="eventType in entry.eventType"
                                                >
                                                    {{ eventType }}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr v-if="entry.accessibility.length">
                                        <th>
                                            {{
                                                labels['accessibility'][locale]
                                            }}
                                        </th>
                                        <td class="detailViewPage__tableText">
                                            <ul>
                                                <li
                                                    v-for="accessibility in entry.accessibility"
                                                >
                                                    {{ accessibility }}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <!--                                    <tr-->
                                    <!--                                        v-if="entry.program && !isLongplayModal"-->
                                    <!--                                        :class="{-->
                                    <!--                                            detailViewPage__groupedColumns:-->
                                    <!--                                                entry.description,-->
                                    <!--                                        }"-->
                                    <!--                                    >-->
                                    <!--                                        <th>{{ labels['program'][locale] }}</th>-->
                                    <!--                                        <td-->
                                    <!--                                            class="detailViewPage__tableText"-->
                                    <!--                                            v-html="entry.program"-->
                                    <!--                                        ></td>-->
                                    <!--                                    </tr>-->
                                    <tr v-if="entry.description">
                                        <th>
                                            {{ labels['description'][locale] }}
                                        </th>
                                        <td
                                            class="detailViewPage__tableText"
                                            v-html="entry.description"
                                        ></td>
                                    </tr>
                                    <tr v-if="entry.links.length">
                                        <th>{{ labels['links'][locale] }}</th>
                                        <td class="detailViewPage__tableText">
                                            <ul>
                                                <li v-for="link in entry.links">
                                                    <a
                                                        :href="link.url"
                                                        :target="link.target"
                                                    >
                                                        {{ link.text }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
