<script>
    import collect from 'collect.js';

    export default {
        props: {
            availableFilterCriteria: {
                type: Object,
                required: true,
            },
            locale: {
                type: String,
                default: 'en',
            },
            isOpen: {
                type: Boolean,
                default: false,
            },
            inverted: {
                type: Boolean,
                default: false,
            },
            showLegend: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showParticipants: false,

                filterLabels: {
                    month: {
                        en: 'Date',
                        de: 'Datum',
                    },
                    eventType: {
                        en: 'Event type',
                        de: 'Veranstaltungsart',
                    },
                    venue: {
                        en: 'Venues, Locations',
                        de: 'Ort',
                    },
                    participant: {
                        en: 'Participant',
                        de: 'Teilnehmer:innen',
                    },
                    online: {
                        en: 'Live / Online',
                        de: 'Live / Online',
                    },
                    all: {
                        en: 'All',
                        de: 'Alle',
                    },
                    reset: {
                        en: 'Reset',
                        de: 'Zurücksetzen',
                    },
                    genre: {
                        en: 'Genre',
                        de: 'Genre',
                    },
                    accessibility: {
                        en: 'Accessibility',
                        de: 'Barrierefreiheit',
                    },
                    legend: {
                        en: 'Legend',
                        de: 'Legende',
                    },
                },
            };
        },
        computed: {
            filteredFilters() {
                return collect(this.availableFilterCriteria)
                    .filter()
                    .except(['participant'])
                    .all();
            },
        },
        methods: {
            toggleQuery(name, value) {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                let currentValues = searchParams.getAll(name);
                if (currentValues.includes(value)) {
                    currentValues = currentValues.filter(
                        (v) =>
                            v &&
                            v !== value &&
                            this.availableFilterCriteria[name].includes(v),
                    );
                    searchParams.delete(name);
                    currentValues.forEach((currentValue) => {
                        searchParams.append(name, currentValue);
                    });
                } else {
                    searchParams.append(name, value);
                }
                this.updateQuery(searchParams);
            },
            setQuery(name, value) {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                searchParams.set(name, value);
                this.updateQuery(searchParams);
            },
            updateQuery(searchParams) {
                window.history.pushState(
                    '',
                    null,
                    `${window.location.pathname}${
                        searchParams ? '?' + searchParams : ''
                    }`,
                );
                this.$emit('updated-query');
                this.$forceUpdate();
            },
            setShowParticipants(showParticipants) {
                this.showParticipants = showParticipants;
            },
            resetQuery(name) {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                searchParams.delete(name);
                this.updateQuery(searchParams);
            },
            resetFilter() {
                this.updateQuery(null);
            },
            isAll(name) {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                return (
                    !searchParams.has(name) ||
                    searchParams.getAll(name).length === 0
                );
            },
            isActive(name, value) {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                return (
                    searchParams.has(name) &&
                    searchParams.getAll(name).includes(value)
                );
            },
            closeFilter() {
                this.$emit('close');
            },
        },
    };
</script>

<template>
    <div
        class="category-filter"
        :class="{ 'is-open': isOpen, 'category-filter--inverted': inverted }"
    >
        <div class="category-filter__inner flex justify-between flex-col">
            <div>
                <div class="category-filter__nav">
                    <button
                        type="button"
                        class="category-filter__filters"
                        title="Show Filters"
                        :class="{ 'is-active': !showParticipants }"
                        @click="setShowParticipants(false)"
                    />
                    <button
                        v-if="
                            availableFilterCriteria.participant &&
                            availableFilterCriteria.participant.length
                        "
                        type="button"
                        class="category-filter__authors"
                        title="Show Participants"
                        :class="{ 'is-active': showParticipants }"
                        @click="setShowParticipants(true)"
                    />
                    <div class="category-filter__navspacer" />
                    <button
                        type="button"
                        class="category-filter__reset"
                        title="Reset"
                        @click="resetFilter()"
                    >
                        {{ filterLabels.reset[locale] }}
                    </button>
                    <button
                        type="button"
                        class="category-filter__close"
                        title="Close"
                        aria-label="Close"
                        @click="closeFilter()"
                    />
                </div>
                <div v-show="showParticipants" key="participants">
                    <div class="category-filter__section-heading">
                        {{ filterLabels.participant[locale] }}
                    </div>
                    <ul class="category-filter__list">
                        <li class="category-filter__item">
                            <button
                                class="category-filter__link"
                                :class="{ 'is-active': isAll('participant') }"
                                @click="resetQuery('participant')"
                            >
                                {{ filterLabels.all[locale] }}
                            </button>
                        </li>
                        <li
                            v-for="participant in availableFilterCriteria.participant"
                            :key="'calfilter_participant_' + participant"
                            class="category-filter__item"
                        >
                            <button
                                class="category-filter__link"
                                :class="{
                                    'is-active': isActive(
                                        'participant',
                                        participant,
                                    ),
                                }"
                                @click="toggleQuery('participant', participant)"
                            >
                                {{ participant }}
                            </button>
                        </li>
                    </ul>
                </div>
                <div v-show="!showParticipants" key="filters">
                    <template v-for="(items, filter) in filteredFilters">
                        <div class="category-filter__section-heading">
                            {{ filterLabels[filter][locale] }}
                        </div>

                        <ul class="category-filter__list">
                            <li class="category-filter__item">
                                <button
                                    class="category-filter__link"
                                    :class="{ 'is-active': isAll(filter) }"
                                    @click="resetQuery(filter)"
                                >
                                    {{ filterLabels.all[locale] }}
                                </button>
                            </li>
                            <li
                                v-for="item in availableFilterCriteria[filter]"
                                :key="'calfilter_' + filter + item"
                                class="category-filter__item"
                            >
                                <button
                                    class="category-filter__link"
                                    :class="{
                                        'is-active': isActive(filter, item),
                                    }"
                                    @click="toggleQuery(filter, item)"
                                >
                                    {{ item }}
                                </button>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
            <div v-if="showLegend" class="border-t border-white">
                <h3 class="uppercase my-[1em]">
                    {{ filterLabels['legend'][locale] }}
                </h3>
                <ul class="space-y-2">
                    <li class="flex space-x-4">
                        <div
                            class="shrink-0 grow-0 w-4 h-4 bg-black rounded-full category-filter__legendLabel border border-white"
                        ></div>
                        <span>Event</span>
                    </li>
                    <li class="flex space-x-4">
                        <div
                            class="shrink-0 grow-0 w-4 h-4 bg-[#a7a7a7] rounded-full category-filter__legendLabel border border-black"
                        ></div>
                        <span>Longplay</span>
                    </li>
                    <!--                    <li class="inline-flex space-x-4">-->
                    <!--                        <div-->
                    <!--                            class="shrink-0 grow-0 w-4 h-4 bg-white rounded-full category-filter__legendLabel border border-black"-->
                    <!--                        ></div>-->
                    <!--                        <span class="uppercase">Sonic Matter</span>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </div>
    </div>
</template>
